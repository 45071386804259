import React from 'react';
import {
  MenuContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
    MenuContents,
  SidebarRoute,
  SideBtnWrap, MenuIcon
} from './MenuButtonElements';
import {FaBars} from "react-icons/fa";

const MenuButton = ({toggle}) => {
  let button

  return (
      <>
      <MenuContainer >
        <Icon onClick={toggle}>
            <FaBars />
        </Icon>
      </MenuContainer>
      </>
  );
};

export default MenuButton;
