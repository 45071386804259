import React from 'react';
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ListViewPage from "./pages/listview";
import ResHome from "./pages";
import MenuPage from "./pages/menupage";
import CustomConfig from "./pages/custom";

function App() {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<ResHome />}  />
                <Route path='/orderform' element={<ListViewPage/>} />
                <Route path='/menupage' element={<MenuPage/>} />
                <Route path='/customconfig' element={<CustomConfig/>} />
            </Routes>
        </Router>
    );
}

export default App;
