import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
  NavBtn,
  NavBtnLink
} from './NavbarElements';
import MenuButton from '../MenuButton'

const Navbar = ({ toggle, mediaView }) => {
  const [scrollNav, setScrollNav] = useState(false);
    const [isOpen, setIsOpen] = useState(false);


  const changeNav = () => {
    if (window.scrollY >= 5) {
      setScrollNav(true);
      console.log("scrolled")
    } else {
      setScrollNav(false);
      console.log("didnt scroll")
    }
  };
  let button
  if (mediaView) {
      button = <NavBtnLink to='/orderform' onClick={mediaView}>Plain View</NavBtnLink>;
    } else {
      button = <NavBtnLink to='/' onClick={mediaView}>Media View</NavBtnLink>;
    }

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#000' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo onClick={toggleHome} to='/'>
              RestaurantName
            </NavLogo>
            { /*<NavMenu>
              <NavItem>
                <NavLinks
                    to='appetizers'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-80}
                >
                  Appetizers/Botanas
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                    to='res'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-80}
                >
                  Beef/Res
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                    to='Pollo'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-80}
                >
                  Chicken/Pollo
                </NavLinks>
              </NavItem>
            </NavMenu> */}
            <MenuButton toggle={toggle}/>
            {/* <NavBtn>
              {button}
            </NavBtn> */}
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
