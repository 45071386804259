import { Link as LinkS } from 'react-scroll';
import { Link as LinkR } from 'react-router-dom';
import styled from 'styled-components';

export const MenuContainer = styled.div`
  background: lightsteelblue;
  padding-top: 70px;
  margin-bottom: 5px;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  overflow: auto;

    
    `;

export const MenuWrapper = styled.div`
  padding-bottom: 20px;
  overflow: auto;
  margin-left: auto;
  margin-right: auto;

`;

export const DishContainer = styled.div`

`;

export const DishTitle = styled.h2`
  font-size: 1.5rem;
  color: black;
  text-align: center;
  font-weight: bold;
  padding-left: 5px;

  @media screen and (max-width: 480px) {
    font-size: 1rem;
  }
    `;


export const MenuH1 = styled.h1`
  font-size: 2.5rem;
  color: black;
  margin-bottom: 5px;
  padding: 10px; 
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const DishDescription = styled.div`
  font-size: .82rem;
  padding-left: 15px;
    text-align: center;


    `;

export const Price = styled.p`
  font-size: 1rem;
  text-align: center;
  color: darkgreen;
  padding-top: 2px;
  padding-right 10px;


`;

export const Seperator = styled.hr`
    width:50%;
    text-align: center;
    height:1px;
    background-color:black;
    border-style: solid;
    margin: auto;
        `;
