import React, {useState} from "react";
import Footer from "../components/FooterV2";
import Navbar from "../components/NavbarV2";
import Sidebar from "../components/SidebarV2";
import Menu from "../components/Menu";

function MenuPage() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <Menu />
            <Footer />
        </>
    );
}

export default MenuPage;