import React, {useEffect, useState} from 'react';
import {
  MenuContainer, Content, DishImage
} from './MenuElements';
import {ServicesCard, ServicesIcon} from "../ResServices/ServicesElements";

const ImageOnZoom = ({ imagePath }) => {
  return (
    <img
      src={imagePath}
      alt=""
      style={{ overflow: "hidden" }}
      onMouseOver={(e) => (e.currentTarget.style = { transform: "scale(1.25)", overflow: "hidden" })}
      onMouseOut={(e) => (e.currentTarget.style = { transform: "scale(1)", overflow: "hidden" })}
    />
  );
};

const Menu = () => {
    let imagePath = '/newimages/somemaris.jpg'
    return (
        <MenuContainer>
            <Content>
                <DishImage src={imagePath}/>

                Hello
            </Content>
        </MenuContainer>
    )
};

export default Menu