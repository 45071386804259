import React, {useState} from "react";
import GridContainer from "../components/GridContainer"
import Sidebar from "../components/CustomSidebar";
import Navbar from "../components/CustomNavbar";

function CustomConfig() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMediaView, setMediaView] = useState(true);

    const toggle = () => {
    setIsOpen(!isOpen);
  };

    const mediaView = () => {
    setMediaView(!isMediaView);
  };
  return (
      <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <GridContainer/>
      </>
    )
}

export default CustomConfig;