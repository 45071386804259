import React from 'react';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarRoute,
  SideBtnWrap, SidebarHeading
} from './SidebarElements';

const Sidebar = ({ isOpen, toggle , mediaView}) => {
  let button
  if (mediaView) {
      button = <SidebarRoute to='/orderform'>Plain View</SidebarRoute>;
    } else {
      button = <SidebarRoute to='/' onClick={mediaView}>Media View</SidebarRoute>;
    }
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarHeading>Menu</SidebarHeading>
        <SidebarMenu>
          <SidebarLink
            to='appetizers'
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
          >
            Appetizers/Botanas
          </SidebarLink>
          <SidebarLink
            to='res'
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
          >
            Beef/Res
          </SidebarLink>
          <SidebarLink
            to='Pollo'
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
          >
            Chicken/Pollo
          </SidebarLink>
          <SidebarLink
            to='mariscos'
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
          >
            Seafood/Mariscos
          </SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute to='/'>Layout #1 - Media Carousel </SidebarRoute>
          <SidebarRoute to='/orderform'>Layout #2 - 1 Column List </SidebarRoute>
          <SidebarRoute to='/customconfig'>Layout #3 - 3 Column List </SidebarRoute>

        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
