import styled from 'styled-components';

export const MenuContainer = styled.div`
  background: white;

`;

export const Content = styled.p`
    display: flex;
    justify-content: center;

    align-text: center;
`;

export const DishImage = styled.img`

  background-image: url(${props => props.url});
  max-height: 100%;
  max-width: 100%;

  display: block;
  margin-left: auto;
  margin-right: auto;
  transform: scale(.025);
  overflow: hidden;

  &:hover {
    transform: scale(1.25);
    transition: all 0.2s ease-in-out;
    overflow: hidden;
  }
`;

export const ServicesIcon = styled.img`
  max-height: 100%;
  max-width: 100%;

  display: block;
  margin-left: auto;
  margin-right: auto;
`;