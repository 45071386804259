import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.footer`
  background-color: dimgrey;
  
`;

export const FooterWrap = styled.div`
  padding: 10px 10px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  
  @media screen and (max-width: 700px) {
    flex-direction: column;

    padding: 10px;
      justify-content: center;

    align-items: center;
    width: 100%;
    justify-content: space-between;

  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: bold;
  text-align: center;
    @media screen and (max-width: 700px) {
    flex-direction: column;

    padding: 10px;
      justify-content: center;

    align-items: center;
    width: 100%;
    justify-content: space-between;

  }
`;

export const WebsiteRights = styled.small`
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  margin-left: auto;
    text-align: center;
    padding-right: 20px;

  font-weight: bold;
    @media screen and (max-width: 700px) {
    flex-direction: column;

    padding: 10px;
      justify-content: center;

    align-items: center;
    width: 100%;
    justify-content: space-between;

  }

`;
