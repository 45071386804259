import React, {useState} from "react";
import ResFooter from "../components/ResFooter";
import Sidebar from "../components/CustomSidebar";
import Navbar from "../components/CustomNavbar";
import Services from "../components/ResServices";



function ResHome() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMediaView, setMediaView] = useState(true);

    const toggle = () => {
        console.log("clicked menu is ", isOpen)
    setIsOpen(!isOpen);
  };

    const mediaView = () => {
    setMediaView(!isMediaView);
  };
  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle} mediaView={mediaView}/>
        <Navbar toggle={toggle} mediaView={mediaView}/>
        <Services />
        <ResFooter />
    </>
  );
}

export default ResHome;