import React from "react";
import {Content, Container, Column, Border} from './ContainerElements';


const MainContainer = () => {

    return (

            <Container>
                <Column>
                    <Content>Column 1</Content>
                    <Border/>
                </Column>
                <Column>
                    <Content>Column 1</Content>
                    <Border/>
                </Column>
                <Column>
                    <Content>Column 1</Content>
                </Column>

            </Container>
    )
};

export default MainContainer