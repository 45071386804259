import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';


export const OptionsContainer = styled.div`
    background: white;
    padding-top: 10px;
    height: 100%;
    overflow: auto;
    display: flex;
    `;

export const Divider = styled.div`
border-left: 6px solid lightsteelblue;

`;


export const OptionLeft = styled.div`
    overflow: auto;
      margin-left: auto;
  margin-right: auto;


    `;

export const OptionRight = styled.div`
        overflow: auto;
          margin-left: auto;
  margin-right: auto;
  

    `;

export const OptionText = styled(LinkR)`
    color: black;
    font-size: 1.5rem;
    text-align: center;

    `;

